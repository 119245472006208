<ng-container *ngIf="!signUpVerification">
	<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
	<h2 mat-dialog-title>Sign Up</h2>
	<form [formGroup]="sighUpForm">
		<mat-dialog-content>
			<p class="qurator-text">
				Your personal information will not be visible to the public. This will
				remains private until you give consent to a transaction, at which point
				it will be shared solely with the counterparty. To safeguard user
				identities, all users are assigned platform-generated unique aliases.”
			</p>

			<div class="field-wrapper-2-col">
				<mat-form-field class="mr-1">
					<mat-label>First Name</mat-label>
					<input
						type="text"
						matInput
						placeholder="John"
						formControlName="firstName"
					/>
				</mat-form-field>
				<mat-form-field class="ml-1">
					<mat-label>Last Name</mat-label>
					<input
						type="text"
						matInput
						placeholder="Smith"
						formControlName="lastName"
					/>
				</mat-form-field>
			</div>
			<div class="mb-12 field-wrapper-2-col">
				<mat-form-field class="mr-1">
					<mat-label>Middle Name</mat-label>
					<input
						type="text"
						matInput
						placeholder="Carl"
						formControlName="middleName"
					/>
				</mat-form-field>
				<mat-form-field appearance="fill" class="ml-1">
					<mat-label>Country</mat-label>
					<mat-select formControlName="countryId">
						<mat-option
							*ngFor="let country of countryList"
							[value]="country.id"
						>
							{{ country.value }}
						</mat-option>
					</mat-select>
					<mat-hint
						>We are currently expanding services to other jurisdictions. To
						prioritize your desired destination, please send us a message via
						'Contact Us'</mat-hint
					>
				</mat-form-field>
			</div>
			<div class="field-wrapper-2-col">
				<mat-form-field class="mr-1">
					<mat-label>Email(Username)</mat-label>
					<input
						type="email"
						matInput
						placeholder="someone@domain.com"
						formControlName="email"
					/>
				</mat-form-field>
				<mat-form-field class="ml-1">
					<mat-label>Mobile</mat-label>
					<input
						type="phone"
						matInput
						placeholder="0000000000"
						formControlName="mobilePhone"
					/>
				</mat-form-field>
			</div>
			<div class="field-wrapper">
				<mat-form-field>
					<mat-label>Address</mat-label>
					<input
						type="text"
						matInput
						placeholder="9/65 Nine Street, kellyville, 2121"
						formControlName="address"
					/>
				</mat-form-field>
			</div>
			<div class="field-wrapper-2-col">
				<mat-form-field class="mr-1">
					<mat-label>Password</mat-label>
					<input
						type="password"
						matInput
						formControlName="password"
					/>
					<password-strength-meter
						[minPasswordLength]="6"
						[password]="sighUpForm.value.password"
					></password-strength-meter>
				</mat-form-field>
				<mat-form-field class="ml-1">
					<mat-label>Confirm Password</mat-label>
					<input
						type="password"
						matInput
						formControlName="confirmPassword"
					/>
				</mat-form-field>
			</div>
			<p *ngIf="errorMessage" class="error">
				{{ errorMessage }}
			</p>
		</mat-dialog-content>
		<mat-dialog-actions>
			<div class="button-wrapper">
				<button mat-button mat-dialog-close color="secondry" class="mr-2">
					Cancel
				</button>
				<button
					[disabled]="isLoading || !sighUpForm.valid"
					(click)="signUp()"
					type="submit"
					mat-button
					color="primary"
				>
					Create Account
				</button>
			</div>
		</mat-dialog-actions>
	</form>
</ng-container>
<ng-container *ngIf="signUpVerification">
	<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
	<h2 mat-dialog-title>Verify Sign Up</h2>
	<form [formGroup]="verifySighUpForm">
		<mat-dialog-content>
			<p class="qurator-text">
				Volutpat amet, Donec lorem. nisl. est. eu cursus nulla, maximus nec diam
				Cras faucibus tortor. odio odio venenatis ultrices
			</p>
			<div class="field-wrapper-2-col">
				<mat-form-field>
					<input
						type="email"
						readonly="true"
						matInput
						placeholder="Username"
						formControlName="username"
					/>
				</mat-form-field>
				<mat-form-field>
					<input
						type="text"
						matInput
						placeholder="Email address verification code"
						formControlName="emailVerificationCode"
					/>
				</mat-form-field>
			</div>
			<div class="field-wrapper-2-col">
				<mat-form-field>
					<input
						type="password"
						matInput
						placeholder="Password"
						formControlName="password"
					/>
				</mat-form-field>
				<mat-form-field>
					<input
						type="text"
						matInput
						placeholder="Mobile Phone verification code"
						formControlName="smsVerificationCode"
					/>
				</mat-form-field>
			</div>
			<p *ngIf="errorMessage" class="error">
				{{ errorMessage }}
			</p>
			<p *ngIf="infoMessage" class="info">
				{{ infoMessage }}
			</p>
		</mat-dialog-content>
		<mat-dialog-actions>
			<div class="button-wrapper">
				<button mat-button mat-dialog-close color="secondry" class="mr-2">
					Cancel
				</button>
				<button
					[disabled]="isLoading || !sighUpForm.valid"
					(click)="signInWithVerificationCodes()"
					type="submit"
					mat-button
					color="primary"
				>
					Verify
				</button>
			</div>
			<div class="button-wrapper">
				<button
					(click)="reSendVerificationCodeCallBack('email')"
					mat-button
					class="mr-2"
					color="primary"
				>
					Resend email verification code
				</button>
				<button
					(click)="reSendVerificationCodeCallBack('sms')"
					mat-button
					color="primary"
				>
					Resend sms verification code
				</button>
			</div>
		</mat-dialog-actions>
	</form>
</ng-container>
