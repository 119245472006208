import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './ui/header/header.component';
import { FooterComponent } from './ui/footer/footer.component';
import { MaterialModule } from './material/material.module';
import { RouterModule } from '@angular/router';
import { NotificationComponent } from './ui/notification/notification.component';
import { ConfirmationComponent } from './ui/confirmation/confirmation.component';

@NgModule({
	imports: [CommonModule, MaterialModule, RouterModule],
	declarations: [HeaderComponent, FooterComponent, NotificationComponent, ConfirmationComponent],
	exports: [HeaderComponent, FooterComponent, NotificationComponent, ConfirmationComponent],
})
export class SharedModule {}
