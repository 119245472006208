import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { map } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.startsWith('assets/'))
        return next.handle(request);
        
        if (request.url.startsWith(environment.apiUrl) && request.url.endsWith('refresh-token')) {
            return next.handle(request);
        }
        // add auth header with jwt if user is logged in and request is to the api url
        const token = this.authenticationService.tokenData;
        const isLoggedIn = (token?.jwtToken != null);
        const isApiUrl = request.url.startsWith(environment.apiUrl);
        if (isLoggedIn && isApiUrl) {
            this.authenticationService.refreshToken().pipe(map((token) => {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
                return next.handle(request);
            })).subscribe();

            /*
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token?.jwtToken}`
                }
            });
            */
        }
        return next.handle(request);
    }
}