<nav class="fixed top-0 left-0 right-0 z-50 bg-white-900">
	<div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
		<div class="flex items-center justify-between h-24">
			<div class="flex items-center">
				<a class="flex-shrink-0" [routerLink]="['/']">
					<img class="h-16" src="./assets/images/erodrop.svg" alt="erodrop" />
				</a>
				<div class="hidden md:block" *ngIf="isAuth">
					<div class="flex items-baseline ml-10 space-x-4">
						<a
							[routerLink]="['dashboard']"
							routerLinkActive="active"
							class="flex flex-row items-center justify-center px-3 py-2 text-sm font-medium text-purple-400 rounded-md hover:text-purple-900"
							aria-current="Dashboard"
						>
							<mat-icon
								aria-hidden="false"
								aria-label="home"
								fontIcon="home"
								class="mr-1"
							></mat-icon
							>Dashboard</a
						>
						<a
							[routerLink]="['dashboard/requests']"
							routerLinkActive="active"
							class="flex flex-row items-center justify-center px-3 py-2 text-sm font-medium text-purple-400 rounded-md hover:text-purple-900"
							aria-current="requests"
							><mat-icon
								aria-hidden="false"
								aria-label="local_shipping"
								fontIcon="local_shipping"
								class="mr-1"
							></mat-icon
							>Requests</a
						>

						<a
							[routerLink]="['dashboard/offers']"
							routerLinkActive="active"
							href="dashboard/offers"
							class="flex flex-row items-center justify-center px-3 py-2 text-sm font-medium text-purple-400 rounded-md hover:text-purple-900"
							aria-current="offers"
							><mat-icon
								aria-hidden="false"
								aria-label="approval_delegation"
								fontIcon="approval_delegation"
								class="mr-1"
							></mat-icon
							>Offers</a
						>
					</div>
				</div>
			</div>
			<div class="hidden md:block">
				<div class="flex items-center ml-4 md:ml-6">
					<a
						[routerLink]="['about']"
						routerLinkActive="active"
						class="flex flex-row items-center justify-center px-3 py-2 text-sm font-medium text-purple-400 rounded-md hover:text-purple-900"
						aria-current="about"
						>About</a
					>
					<a
						[routerLink]="['faq']"
						routerLinkActive="active"
						class="flex flex-row items-center justify-center px-3 py-2 text-sm font-medium text-purple-400 rounded-md hover:text-purple-900"
						aria-current="about"
						>FAQ</a
					>
					<a
						[routerLink]="['contact']"
						routerLinkActive="active"
						class="flex flex-row items-center justify-center px-3 py-2 text-sm font-medium text-purple-400 rounded-md hover:text-purple-900"
						aria-current="about"
						>Contact</a
					>
					<button class="mr-2" *ngIf="!isAuth" (click)="signIn()" mat-button color="primary">
						Sign In
					</button>
					<button *ngIf="!isAuth" (click)="signUp()" mat-button color="secondry">
						Sign Up
					</button>
					<ng-container *ngIf="isAuth">
						<button
							class="rounded-full bg-purple-400 text-white-900 py-1.5 px-3"
							color="primary"
							[matMenuTriggerFor]="menu"
						>
							{{ userInitial }}
						</button>
						<mat-menu #menu="matMenu">
							<label mat-subheader mat-menu-item>
								{{ user.firstName }} {{ user.lastName }}
							</label>
							<button (click)="signOut()" mat-menu-item>Sign out</button>
						</mat-menu>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
	<hr class="border-gray-200" />
</nav>
