<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
	<p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions>
	<div class="button-wrapper">
		<button
			mat-button
			(click)="cancelHandler()"
			color="secondry"
			class="mr-2"
		>
			Cancel
		</button>
		<button (click)="acceptHandler()" mat-button color="primary">
			Accept
		</button>
	</div>
</mat-dialog-actions>
