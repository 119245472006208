import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	public isWelcome: boolean = false;
	constructor(private router: Router) {}

	title = 'erodrop';

	ngOnInit() {
		this.router.events
			.pipe(
				filter(
					(event): event is NavigationEnd =>
						event instanceof NavigationEnd
				)
			)
			.subscribe((event: NavigationEnd) => {
				this.isWelcome = event.url === '/';
			});
	}
}
