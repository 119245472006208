import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service'
import { AuthSignInRequestDataModel } from '../../models/auth.data.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });

  forgotPwLoginForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    newPassword: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required),
    verificationCode: new FormControl('', Validators.required)
  });

  errorMessage: string = '';
  infoMessage: string = ''
  isForgotPassword: boolean = false;
  isLoading: boolean = false;

  constructor(private authenticationService: AuthenticationService, private router: Router, public dialogRef: MatDialogRef<SignInComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  /**
   *
   *
   * @memberof SignInComponent
   */
  submit() {
    if (!this.isLoading && this.loginForm.valid) {
      this.isLoading = true;
      const request: AuthSignInRequestDataModel = {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
        emailVerificationCode :null,
        smsVerificationCode : null
      }
      this.authenticationService.signIn(request).subscribe({
        next: () => {
          this.isLoading = false;
          this.router.navigate(["dashboard"]);
          this.dialogRef.close();
        },
        error: (error: Error) => {
          this.isLoading = false;
          this.errorMessage = error.message;
        }
      })
    }
  }

  /**
   *
   *
   * @return {*}
   * @memberof SignInComponent
   */
  forgotPasswordLogin() {
    this.errorMessage = '';
    if (this.forgotPwLoginForm.valid) {
      if (this.forgotPwLoginForm.value.newPassword != this.forgotPwLoginForm.value.confirmPassword) {
        this.errorMessage = "The conform password is invalid.";
        return;
      }
      const request: AuthSignInRequestDataModel = {
        username: this.forgotPwLoginForm.value.username,
        password: this.forgotPwLoginForm.value.newPassword,
        emailVerificationCode: this.forgotPwLoginForm.value.verificationCode,
        smsVerificationCode: this.forgotPwLoginForm.value.verificationCode
      }
      this.authenticationService.signIn(request).subscribe({
        next: () => {
          this.router.navigate(["dashboard"]);
          this.dialogRef.close();
        },
        error: (error: Error) => {
          this.errorMessage = error.message;
        }
      })
    }
  }

  /**
   *
   *
   * @return {*}
   * @memberof SignInComponent
   */
  forgotPwRequestCallBack() {
    this.infoMessage = '';
    if (!this.loginForm.value.username) {
      this.errorMessage = "Please enter valid username."
      return;
    }
    this.isLoading = true;
    this.authenticationService.resetPassword(this.loginForm.value.username).subscribe({
      next: () => {
        this.infoMessage = 'The verification code has sent. Please check your email or SMS.'
        this.forgotPwLoginForm.patchValue({
          username: this.loginForm.value.username
        });
        this.isForgotPassword = true;
        this.isLoading = false;
      },
      error: (error: Error) => {
        this.errorMessage = error.message;
        this.isLoading = false;
      }
    });
  }

  /**
   *
   *
   * @return {*}
   * @memberof SignInComponent
   */
  reSendVerificationCodeCallBack() {
    this.infoMessage = '';
    if (!this.forgotPwLoginForm.value.username) {
      this.errorMessage = "Please enter valid username."
      return;
    }
    this.isLoading = true;
    this.authenticationService.reSendVerificationCode(this.forgotPwLoginForm.value.username).subscribe({
      next: () => {
        this.isLoading = false;
      },
      error: (error: Error) => {
        this.infoMessage = '';
        this.errorMessage = error.message;
        this.isLoading = false;
      }
    });
  }
}
