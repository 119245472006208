import { NgModule } from '@angular/core';
import { MaterialModule } from '../shared/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';

@NgModule({
	declarations: [SignUpComponent, SignInComponent],
	imports: [
		CommonModule,
		MaterialModule,
		HttpClientModule,
		ReactiveFormsModule,
		PasswordStrengthMeterModule.forRoot(),
	],
	providers: [],
	exports: [SignUpComponent, SignInComponent],
})
export class CoreModule {}
