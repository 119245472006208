<ng-container *ngIf="!isForgotPassword">
	<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
	<h2 mat-dialog-title>Sign In</h2>
	<form [formGroup]="loginForm">
		<mat-dialog-content>
			<div class="field-wrapper">
				<mat-form-field>
					<mat-label>Username(Email)</mat-label>
					<input
						type="email"
						matInput
						formControlName="username"
					/>
				</mat-form-field>
				<mat-form-field>
					<mat-label>Password</mat-label>
					<input
						type="password"
						matInput
						formControlName="password"
					/>
				</mat-form-field>
				<p *ngIf="errorMessage" class="error">
					{{ errorMessage }}
				</p>
			</div>
		</mat-dialog-content>
		<mat-dialog-actions>
			<div class="button-wrapper">
				<button
					mat-button
					mat-dialog-close
					color="secondry"
					class="mr-2"
				>
					Cancel
				</button>
				<button
					[disabled]="isLoading || !loginForm.valid"
					(click)="submit()"
					type="submit"
					mat-button
					color="primary"
				>
					Login
				</button>
			</div>
			<div class="button-wrapper">
				<button (click)="forgotPwRequestCallBack()" mat-button>
					Forgot Password
				</button>
			</div>
		</mat-dialog-actions>
	</form>
</ng-container>

<ng-container *ngIf="isForgotPassword">
	<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
	<h2 mat-dialog-title>Sign In With Verification Code</h2>
	<form [formGroup]="forgotPwLoginForm">
		<mat-dialog-content>
			<p class="qurator-text">
				Volutpat amet, Donec lorem. nisl. est. eu cursus nulla, maximus
				nec diam Cras faucibus tortor. odio odio venenatis ultrices
			</p>

			<div class="field-wrapper">
				<mat-form-field>
					<input
						type="password"
						matInput
						placeholder="New Password"
						formControlName="newPassword"
						autocomplete="off"
					/>
				</mat-form-field>
				<mat-form-field>
					<input
						type="password"
						matInput
						placeholder="Confirm Password"
						formControlName="confirmPassword"
						autocomplete="off"
					/>
				</mat-form-field>
				<mat-form-field>
					<input
						type="text"
						matInput
						placeholder="Verification Code"
						formControlName="verificationCode"
					/>
				</mat-form-field>
				<p *ngIf="errorMessage" class="error">
					{{ errorMessage }}
				</p>
				<p *ngIf="infoMessage" class="info">
					{{ infoMessage }}
				</p>
			</div>
		</mat-dialog-content>
		<mat-dialog-actions>
			<div class="button-wrapper">
				<button
					mat-button
					mat-dialog-close
					color="secondry"
					class="mr-2"
				>
					Cancel
				</button>
				<button
					[disabled]="!forgotPwLoginForm.valid"
					(click)="forgotPasswordLogin()"
					type="submit"
					mat-button
					color="primary"
				>
					Login
				</button>
			</div>
			<button (click)="reSendVerificationCodeCallBack()" mat-button>
				Resend verification code
			</button>
		</mat-dialog-actions>
	</form>
</ng-container>
