import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignUpComponent } from '@core/components/sign-up/sign-up.component';
import { SignInComponent } from 'app/core/components/sign-in/sign-in.component';
import { UserDataModel } from 'app/core/models/auth.data.model';
import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
	userInitial: string = '';
	user = new UserDataModel();
	authSub: any;
	isAuth = false;

	constructor(
		public dialog: MatDialog,
		private authenticationService: AuthenticationService
	) {}

	ngOnInit() {
		this.authSub = this.authenticationService.auth.subscribe(() => {
			this.isAuth = this.authenticationService.isAuthenticated;
			if (this.authenticationService.isAuthenticated) {
				this.authenticationService.userInfo().subscribe({
					next: (user: UserDataModel) => {
						this.user = user;
						this.userInitial = `${user.firstName?.charAt(
							0
						)}${user.lastName?.charAt(0)}`.toUpperCase();
					},
					error: (error) => {},
				});
			}
		});
	}

	ngOnDestroy() {
		this.authSub?.unsubscribe();
	}

	signOut() {
		this.authenticationService.signOut();
	}

	/**
	 *
	 *
	 * @memberof HeaderComponent
	 */
	signIn() {
		const dialogRef = this.dialog.open(SignInComponent, {
			width: '750px',
		});
		dialogRef.afterClosed().subscribe((result) => {});
	}

	/**
	 *
	 *
	 * @memberof ShellComponent
	 */
	signUp() {
		const dialogRef = this.dialog.open(SignUpComponent, {
			width: '750px',
		});
		dialogRef.afterClosed().subscribe((result) => {});
	}

	/**
	 *
	 *
	 * @memberof HeaderComponent
	 */
	handleSettings() {}
}
