
<ng-container [ngSwitch]="data.type">
	<div
		*ngSwitchCase="'success'"
		class="flex flex-row items-center justify-between gap-4 pt-2 pb-2 pl-4 pr-4 bg-green-100 rounded-lg"
	>
		<p class="text-white-900">{{ data.message }}</p>
		<button mat-button color="warn" (click)="closeHandler()">Close</button>
	</div>
	<div
		*ngSwitchCase="'error'"
		class="flex flex-row items-center justify-between gap-4 pt-2 pb-2 pl-4 pr-4 bg-red-100 rounded-lg"
	>
		<p class="text-white-900">{{ data.message }}</p>
		<button mat-button color="warn" (click)="closeHandler()">Close</button>
	</div>
	<div
		*ngSwitchCase="'info'"
		class="flex flex-row items-center justify-between gap-4 pt-2 pb-2 pl-4 pr-4 bg-purple-400 rounded-lg"
	>
		<p class="text-white-900">{{ data.message }}</p>
		<button mat-button color="warn" (click)="closeHandler()">Close</button>
	</div>
	<div
		*ngSwitchCase="'worning'"
		class="flex flex-row items-center justify-between gap-4 pt-2 pb-2 pl-4 pr-4 bg-orange-500 rounded-lg"
	>
		<p class="text-white-900">{{ data.message }}</p>
		<button mat-button color="warn" (click)="closeHandler()">Close</button>
	</div>
	<div
		*ngSwitchDefault
		class="flex flex-row items-center justify-between gap-4 pt-2 pb-2 pl-4 pr-4 bg-orange-500 rounded-lg"
	>
		<p class="text-white-900">{{ data.message }}</p>
		<button mat-button color="warn" (click)="closeHandler()">Close</button>
	</div>
</ng-container>
