import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service'
import { AuthSignUpRequestDataModel, AuthSignInRequestDataModel } from '../../models/auth.data.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  sighUpForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    mobilePhone: new FormControl('', [Validators.required, Validators.minLength(10)]),
    firstName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    middleName: new FormControl('', [Validators.maxLength(100)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    countryId: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)])
  });

  verifySighUpForm: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    emailVerificationCode: new FormControl('', [Validators.required, Validators.minLength(6)]),
    smsVerificationCode: new FormControl('', [Validators.required, Validators.minLength(6)])
  });

  errorMessage: string = '';
  infoMessage: string = '';
  isLoading: boolean = false;
  countryList: any[] = [];
  signUpVerification = false;

  constructor(private authenticationService: AuthenticationService,
    private router: Router,
    public dialogRef: MatDialogRef<SignUpComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.isLoading = true;
    this.authenticationService.signUpData().subscribe({
      next: (response) => {
        this.countryList = response.countryList;
        this.isLoading = false;;
      },
      error: (error: Error) => {
        this.errorMessage = error.message;
        this.isLoading = false;
      }
    });
  }

  /**
   *
   *
   * @memberof SignUpComponent
   */
  signUp() {
    this.infoMessage = '';
    this.errorMessage = '';
    if (!this.isLoading && this.sighUpForm.valid) {
      if (this.sighUpForm.value.password != this.sighUpForm.value.confirmPassword) {
        this.errorMessage = 'The Confirm Password password is not match with provided password.';
        return;
      }
      this.isLoading = true;
      const request: AuthSignUpRequestDataModel = {
        email: this.sighUpForm.value.email,
        mobilePhone: this.sighUpForm.value.mobilePhone,
        firstName: this.sighUpForm.value.firstName,
        middleName: this.sighUpForm.value.middleName,
        lastName: this.sighUpForm.value.lastName,
        countryId: this.sighUpForm.value.countryId,
        address: this.sighUpForm.value.address,
        password: this.sighUpForm.value.password
      }
      this.authenticationService.signUp(request).subscribe({
        next: () => {
          this.isLoading = false;
          this.verifySighUpForm.patchValue({
            username: this.sighUpForm.value.email
          });
          this.verifySighUpForm.updateValueAndValidity();
          this.signUpVerification = true;
        },
        error: (error: Error) => {
          this.isLoading = false;
          this.errorMessage = error.message;
        }
      })
    }
  }

  /**
   *
   *
   * @param {string} [type='email']
   * @return {*}
   * @memberof SignUpComponent
   */
  reSendVerificationCodeCallBack(type: string = 'email') {
    this.infoMessage = '';
    if (!this.verifySighUpForm.value.email) {
      this.errorMessage = "Please enter valid username."
      return;
    }
    this.isLoading = true;
    this.authenticationService.reSendVerificationCode(this.verifySighUpForm.value.email, type, false).subscribe({
      next: () => {
        this.isLoading = false;
        this.infoMessage = (type == 'email') ? 'The email verification code has been sent to your email.' : 'The sms verification code has been sent to your mobile phone.';
      },
      error: (error: Error) => {
        this.infoMessage = '';
        this.errorMessage = error.message;
        this.isLoading = false;
      }
    });
  }

  /**
   *
   *
   * @memberof SignUpComponent
   */
  signInWithVerificationCodes() {
    if (!this.isLoading && this.verifySighUpForm.valid) {
      this.isLoading = true;
      const request: AuthSignInRequestDataModel = {
        username: this.verifySighUpForm.value.username,
        password: this.verifySighUpForm.value.password,
        emailVerificationCode: this.verifySighUpForm.value.emailVerificationCode,
        smsVerificationCode: this.verifySighUpForm.value.smsVerificationCode
      }
      this.authenticationService.signIn(request).subscribe({
        next: () => {
          this.isLoading = false;
          this.router.navigate(["dashboard"]);
          this.dialogRef.close();
        },
        error: (error: Error) => {
          this.isLoading = false;
          this.errorMessage = error.message;
        }
      })
    }
  }
}
