import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmation } from '@shared/models/confirmation.model';

@Component({
	selector: 'app-confirmation',
	templateUrl: './confirmation.component.html',
	styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
	constructor(
		public dialogRef: MatDialogRef<ConfirmationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IConfirmation
	) {}

	cancelHandler(): void {
		this.dialogRef.close(false);
	}

	acceptHandler(): void {
		this.dialogRef.close(true);
	}
}
