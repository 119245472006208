// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	erodrop_fee: 10,
	apiUrl: 'https://erodropapi.azurewebsites.net',
	stripe: {
		publishable_key:
			'pk_test_51MnDtaFObspPvZxC2gZlNidHVOiYsYRC5zSLtSD0Loj0t3q4lQOZZScpobLJESmcHfUdXVnK9GZA0QRDcIgyphaX00ULe0iqCd',
	},
	recaptcha: {
		siteKey: '6LdE1oEnAAAAAJ7_QP9dtV0P3lT0Qkd2QsNVLY-U',
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
