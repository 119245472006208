import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import {
	AuthSignInRequestDataModel,
	AuthSignUpRequestDataModel,
	AuthDataModel,
	UserDataModel,
} from '../models/auth.data.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	private authSubject: BehaviorSubject<AuthDataModel | null>;
	public auth: Observable<AuthDataModel | null>;

	constructor(private router: Router, private http: HttpClient) {
		this.authSubject = new BehaviorSubject(
			JSON.parse(localStorage.getItem('auth')!)
		);
		this.auth = this.authSubject.asObservable();
	}

	/**
	 *
	 *
	 * @readonly
	 * @memberof AuthenticationService
	 */
	public get tokenData() {
		return this.authSubject.value;
	}

	/**
	 *
	 *
	 * @readonly
	 * @memberof AuthenticationService
	 */
	public get isAuthenticated() {
		return this.authSubject.value != null;
	}

	/**
	 *
	 *
	 * @return {*}
	 * @memberof AuthenticationService
	 */
	signUpData() {
		return this.http
			.get<any>(`${environment.apiUrl}/Auth/sign-up-data`)
			.pipe(
				map((response) => {
					return response;
				})
			);
	}

	/**
	 *
	 *
	 * @param {AuthSignUpRequestDataModel} request
	 * @return {*}
	 * @memberof AuthenticationService
	 */
	signUp(request: AuthSignUpRequestDataModel) {
		return this.http
			.post<any>(`${environment.apiUrl}/Auth/sign-up`, request)
			.pipe(
				map((valid) => {
					return valid;
				})
			);
	}

	/**
	 *
	 *
	 * @param {AuthSignInRequestDataModel} request
	 * @return {*}
	 * @memberof AuthenticationService
	 */
	signIn(request: AuthSignInRequestDataModel) {
		return this.http
			.post<any>(`${environment.apiUrl}/Auth/sign-in`, request)
			.pipe(
				map((authData: AuthDataModel) => {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					const token = authData ?? null;
					localStorage.setItem('auth', JSON.stringify(token));
					this.authSubject.next(token);
					this.refreshToken();
					return authData;
				})
			);
	}

	/**
	 *
	 *
	 * @param {string} userName
	 * @return {*}
	 * @memberof AuthenticationService
	 */
	resetPassword(userName: string) {
		return this.http
			.post<any>(`${environment.apiUrl}/Auth/reset-password`, {
				username: userName,
			})
			.pipe(
				map((response: boolean) => {
					return response;
				})
			);
	}

	/**
	 *
	 *
	 * @param {string} userName
	 * @param {string} [type='email']
	 * @param {boolean} [isResetPassword=true]
	 * @return {*}
	 * @memberof AuthenticationService
	 */
	reSendVerificationCode(
		userName: string,
		type: string = 'email',
		isResetPassword = true
	) {
		let url = '';
		if (type == 'email') {
			url = `${environment.apiUrl}/Auth/send-email-verification-code`;
		} else if (type == 'sms') {
			url = `${environment.apiUrl}/Auth/sms-email-verification-code`;
		}

		return this.http
			.post<any>(url, {
				username: userName,
				isResetPassword: isResetPassword,
			})
			.pipe(
				map((response: boolean) => {
					return response;
				})
			);
	}

	/**
	 *
	 *
	 * @memberof AuthenticationService
	 */
	signOut(navigate: boolean = true) {
		// remove user from local storage to log user out
		localStorage.removeItem('auth');
		this.authSubject.next(null);
		if (navigate) {
			this.router.navigate(['/']);
		}
	}

	refreshToken() {
		return new Observable((sub) => {
			const token = this.authSubject.value;
			if (token == null) {
				sub.next(null);
				return;
			}
			const dateStr = token?.expiryDateUtc ?? '';
			const expDate = new Date(dateStr);
			if (expDate.getTime() < new Date().getTime()) {
				this.http
					.get<any>(`${environment.apiUrl}/Auth/refresh-token`)
					.pipe(
						map((response: AuthDataModel) => {
							return response;
						})
					)
					.subscribe({
						next: (authData: AuthDataModel) => {
							const token = authData ?? null;
							localStorage.setItem('auth', JSON.stringify(token));
							this.authSubject.next(token);
							sub.next(token.jwtToken);
						},
						error: (error) => {
							this.signOut();
							sub.next(null);
						},
					});
			} else {
				sub.next(token?.jwtToken);
			}
		});
	}

	/**
	 *
	 *
	 * @return {*}
	 * @memberof AuthenticationService
	 */
	userInfo() {
		return this.http
			.get<any>(`${environment.apiUrl}/Auth/current-user-data`)
			.pipe(
				map((response: UserDataModel) => {
					return response;
				})
			);
	}
}
