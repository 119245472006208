import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/helpers/auth.guard';
import { GuestGuard } from './core/helpers/guest.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () =>
			import('./welcome/welcome.module').then((m) => m.WelcomeModule),
			canActivate: [GuestGuard],
	},
	{
		path: 'welcome',
		loadChildren: () =>
			import('./welcome/welcome.module').then((m) => m.WelcomeModule),
			canActivate: [GuestGuard],
	},
	{
		path: 'dashboard',
		loadChildren: () =>
			import('./dashboard/dashboard.module').then(
				(m) => m.DashboardModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: '**',
		redirectTo: '',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
