import { Component, Inject } from '@angular/core';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarRef,
} from '@angular/material/snack-bar';
import { INotification } from '@shared/models/notification.model';

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: INotification,
		private snackBarRef: MatSnackBarRef<NotificationComponent>
	) {
		console.log('notification: ' , data);
	}

	public closeHandler(): void {
		this.snackBarRef.dismiss();
	}
}
