export class AuthSignInRequestDataModel {
  username?: string;
  password?: string;
  emailVerificationCode = null;
  smsVerificationCode = null;
}

export class AuthSignUpRequestDataModel {
  email?: string;
  mobilePhone?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  countryId?: string;
  address?: string;
  password?: string;
}

export class AuthDataModel {
  id?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  username?: string;
  expiryDateUtc?: string;
  jwtToken?: string | null | undefined;
}

export class UserDataModel {
  email?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
}