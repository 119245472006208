<footer
	class="bg-gradient-to-r from-purple-900 to-purple-400 p-4 bg-white md:px-6 md:py-8 border-t border-gray-200 relative"
>
	<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
		<div class="sm:flex sm:items-center sm:justify-between">
			<a [routerLink]="['/']" class="flex items-center mb-4 sm:mb-0">
				<img
					src="./assets/images/erodrop-white-logo.svg"
					class="h-12 mr-3"
					alt="Erodrop Logo"
				/>
			</a>
			<ul
				class="flex flex-wrap items-center mb-6 text-sm text-white-900 sm:mb-0"
			>
				<li>
					<a
						[routerLink]="['about']"
						routerLinkActive="active"
						class="mr-4 hover:underline md:mr-6"
						>About</a
					>
				</li>
				<li>
					<a
						[routerLink]="['privacy']"
						routerLinkActive="active"
						class="mr-4 hover:underline md:mr-6"
						>Privacy</a
					>
				</li>
				<li>
					<a
						[routerLink]="['terms']"
						routerLinkActive="active"
						class="mr-4 hover:underline md:mr-6"
						>Terms</a
					>
				</li>
				<li>
					<a
						[routerLink]="['faq']"
						routerLinkActive="active"
						class="mr-4 hover:underline md:mr-6"
						>FAQ</a
					>
				</li>
				<li>
					<a
						[routerLink]="['contact']"
						routerLinkActive="active"
						class="hover:underline"
						>Contact</a
					>
				</li>
			</ul>
		</div>
		<hr class="my-2 border-gray-100 sm:mx-auto lg:my-2" />
		<span class="block text-sm text-white-900 sm:text-center"
			>© <a [routerLink]="['/']" class="hover:underline">Erodrop™</a>. All
			Rights Reserved.
		</span>
	</div>
</footer>
